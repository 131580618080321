<template>
  <div class="wrap">
    <div class="wrap-top">
      <!--   <img
        class="wt-left"
        src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwx2.sinaimg.cn%2Flarge%2F007hyfXLly1g2zy05tritg301e01p3yd.gif&refer=http%3A%2F%2Fwx2.sinaimg.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642753155&t=207f08301a6af0304ea2c5076507c94c"
      /> -->
      <div class="wt-right">
        <div class="wt-right-name">
          {{ wechat == 0 ? userInfo.name : userInfo.nickname }}
        </div>
        <div class="wr-right-id" v-if="wechat == 0">
          ID {{ userInfo.koc_id }}
        </div>
      </div>
    </div>
    <div class="wrap-btm">
      <div class="wrap-btm-row van-hairline--bottom"
        @click="jump('/userAbout')">
        <div class="wrap-btm-row-left">
          <img :src="gr" alt="" />
          <span class="label">个人信息</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="wrap-btm-row van-hairline--bottom"
        @click="jump('/bindingChannel')">
        <div class="wrap-btm-row-left">
          <img :src="qd" alt="" />

          <span class="label">渠道绑定</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="wrap-btm-row van-hairline--bottom"
        @click="jump('/withdrawal')">
        <div class="wrap-btm-row-left">
          <img :src="sk" alt="" />
          <span class="label">收款信息</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="wrap-btm-row van-hairline--bottom"
        @click="jump('/withdrawalRecord')">
        <div class="wrap-btm-row-left">
          <!-- <img :src="xs" alt="" /> -->
          <i class="font_family  icon-tixian"></i>
          <span class="label">提现记录</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="wrap-btm-row van-hairline--bottom" @click="jump('/course')">
        <div class="wrap-btm-row-left">
          <img :src="xs" alt="" />
          <span class="label">新手教程</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="wrap-btm-row" @click="jump('/contact')">
        <div class="wrap-btm-row-left">
          <img :src="lc" alt="" />
          <span class="label">联系我们</span>
        </div>
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="wrap-imgs">
      <a v-for="item in noticeList" :key="item.id" :href="item.linkUrl">
        <img :src="item.cover" :alt="item.title" />
      </a>
    </div>
    <div class="submit" @click="logOut">退出登录</div>
  </div>
  <TabBar />
</template>

<script>
import lc from './img/lc.png'
import xs from './img/xs.png'
import qd from './img/qd.png'
import gr from './img/gr.png'
import sk from './img/sk.png'
import { getOperateInfo } from '@/api/task.js'
import { getUserInfo, getwxUserInfo } from '@/api/information.js'
import TabBar from '@/components/tabBar.vue'
import { Dialog } from 'vant'
export default {
  name: 'information',
  components: {
    TabBar
  },
  computed: {
    wechat() {
      return this.$store.state.user.wechat
    }
  },
  async activated() {
    if (this.wechat === 0) {
      this.userInfo = await getUserInfo()
    } else {
      this.userInfo = await getwxUserInfo()
    }
  },
  async mounted() {
    /*     if (this.wechat === 0) {
      this.userInfo = await getUserInfo()
    } else {
      this.userInfo = await getwxUserInfo()
    } */
    // 图片
    this.noticeList = await getOperateInfo({ operatePosition: 'USERINFO' })
  },
  data() {
    return {
      userInfo: {},
      noticeList: [],
      // 4个小图片。被迫转为base64
      lc,
      gr,
      qd,
      sk,
      xs
    }
  },
  methods: {
    jump(target) {
      if (this.wechat == 0) {
        this.$router.push(target)
      } else {
        if (
          target != '/userAbout' &&
          target != '/bindingChannel' &&
          target != '/withdrawal'
        ) {
          this.$router.push(target)
        } else {
          Dialog.alert({
            title: '您好',
            message: '绑定手机号后即可使用更多功能',
            theme: 'round-button',
            confirmButtonText: '去绑定'
          }).then(() => {
            this.$router.push('/binding')
          })
        }
      }
    },
    logOut() {
      Dialog.confirm({
        message: '确定?'
      }).then(() => {
        this.$store.dispatch('setToken', {})
        this.$store.commit('setList', [])
        this.$router.push('/login')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  padding-bottom: 160px;
  .wrap-top {
    width: 100%;
    height: 280px;
    padding: 0px 45px;
    overflow: hidden;
    background-image: linear-gradient(
      to right bottom,
      #fdd100,
      #fec900,
      #ffc100,
      #ffb900,
      #ffb100,
      #fdaa00,
      #fca400,
      #fa9d00,
      #f69700,
      #f29000,
      #ed8a00,
      #e98400
    );
    .wt-left {
      width: 180px;
      height: 180px;
      border-radius: 20px;
    }
    .wt-right {
      text-align: left;
      color: white;
      margin: 50px 20px 0px;
      .wt-right-name {
        font-weight: bold;
        font-size: 40px;
        padding-bottom: 34px;
        letter-spacing: 4px;
      }
      .wr-right-id {
        font-size: 34px;
      }
    }
  }
  .wrap-btm {
    background: white;
    padding: 5px 34px;
    border-radius: 16px;
    margin: -50px 36px 0px;
    .wrap-btm-row {
      display: flex;
      padding: 30px 0px;
      justify-content: space-between;
      align-items: center;
      .wrap-btm-row-left {
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
        i {
          font-size: 40px;
        }
        .label {
          padding: 0px 10px;
        }
      }
    }
  }
  .submit {
    display: inline-block;
    padding: 16px 100px;
    font-size: 34px;
    border-radius: 50px;
    letter-spacing: 2px;
    color: #ff8870;
    font-weight: bold;
    border: 1px solid #ff8870;
  }
  .swiperBox {
    width: 100%;
    padding: 0px 36px 18px;
    overflow: hidden;
  }
  .wrap-imgs {
    padding: 36px;
    img {
      border-radius: 5px;
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
</style>
